import React from "react";
import Paragraph from "./Paragraph";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./hero.json";
import backgroundLine from "./Background Lines";

function Hero_main({ Title, Title2, Para, ButtonTitle, Href }) {
  return (
    <>
      <div className=" absolute  -top-[37%] w-full opacity-20 flex justify-center items-center">
        <Lottie
          animationData={backgroundLine}
          loop={true}
          className="sub-lottie w-full"
        />
      </div>
      <section className="container-wrapper   ">
        <div className="">
          <main className=" relative ">
            <section className="items-center gap-8 grid grid-cols-1 sm:grid-cols-2 justify-between relative ">
              <div className="">
                <h1 className="font-bold lh-1_1 mb-4 text-4xl sm:text-5xl font-space ">
                  {Title}
                </h1>
                <h4 className="font-bold lh-1_1 mb-4 text-2xl sm:text-3xl font-space ">
                  {" "}
                  {Title2}
                </h4>
                <Paragraph className="opacity-80 mb-8 text-base sm:text-2xl mt-2">
                  {Para}
                </Paragraph>
                <div className=" pt-5">
                  <a
                    href={Href}
                    target="_blank"
                    rel="noreferrer"
                    className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
                  >
                    {ButtonTitle}
                  </a>
                </div>
              </div>

              <div className=" z-30 relative">
                {/* <img
              src="images/audit-hero.png"
              alt="img"
              className="w-full z-30 relative"
            /> */}
                {/* <img
              src="images/hero-circ.png"
              alt=""
              className="  top-0  opacity-40"
            /> */}
                <Lottie
                  animationData={groovyWalkAnimation}
                  loop={true}
                  className="  "
                />
              </div>
            </section>
          </main>
        </div>
      </section>
    </>
  );
}

export default Hero_main;
