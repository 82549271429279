import Hero_main from "components/Hero_main";
import Paragraph from "components/Paragraph";

import React from "react";

function Hero() {
  return (
    <>
      <Hero_main
        Title=" Smart Contract Audit"
        Para="A thorough evaluation of the security of your smart contract and
    DApps, aimed at pinpointing any weaknesses  and proposing solutions
    to address them."
        ButtonTitle="Get a quote now"
        Href="https://t.me/BlockAuditReport"
      />
    </>
  );
}

export default Hero;
