import { Col, Row } from "antd";
import React from "react";
import Prologo from "../../assets/Burner.svg";
import Prologo1 from "../../assets/Drainer.svg";
import Prologo2 from "../../assets/Hacker.svg";
import Prologo3 from "../../assets/Recover.svg";
import Prologo4 from "../../assets/automation.svg";
import Prologo5 from "../../assets/monitoring.svg";
import "./index.css";
const data = [
  {
    id: 1,
    title: "Burner Wallet Deployment",
    subtitle:
      "Professional intervention is needed to navigate and deploy burner wallets effectively",
    img: <img src={Prologo} alt="" className="size-img-pro " />,
  },
  {
    id: 2,
    title: "Drainer Deployment",
    subtitle:
      " To protect funds from hackers, effectively deploy drainers. This blocks asset transfers by attacker. ",
    img: <img src={Prologo1} alt="" className="size-img-pro " />,
  },
  {
    id: 3,
    title: "Advanced Hacker Tactics",
    subtitle:
      "Professional help counters sophisticated techniques employed by hackers.",
    img: <img src={Prologo2} alt="" className="size-img-pro " />,
  },
  {
    id: 4,
    title: "Complex Recovery Processes",
    subtitle:
      "In most cases, single transactions are insufficient, requiring expert handling.",
    img: <img src={Prologo3} alt="" className="size-img-pro " />,
  },
  {
    id: 5,
    title: "Need for Automation",
    subtitle:
      "Successful recovery often necessitates automated systems, beyond layman capabilities.",
    img: <img src={Prologo4} alt="" className="size-img-pro " />,
  },
  {
    id: 6,
    title: "Continuous Monitoring",
    subtitle:
      "Continuous monitoring is vital to outsmart malicious actors and secure your wallet effectively.",
    img: <img src={Prologo5} alt="" className="size-img-pro " />,
  },
];
function Professional() {
  return (
    <section className="container-wrapper block">
      <div className="bg-proccess-one px-7 sm:px-32 pb-10">
        <div className=" my-7">
          <h2 className=" text-3xl sm:text-5xl font-space text-center font-bold pt-4 sm:pt-8">
            Why professional help is required?
          </h2>
        </div>
        <Row justify="space-between">
          {data.map((item, index) => (
            <Col key={index} xl={11} lg={9} md={24}>
              <div className="flex justify-center items-center">
                <div className="bg-[#484848] flex justify-between items-center mt-[2.5rem] mb-[2.5rem] width-box w-full">
                  <div>
                    <div className="font-size-pro Kanit  ">{item.title}</div>
                    <div className="Kanit font-size1-pro font-space">
                      {item.subtitle}
                    </div>
                  </div>
                  <div className="bg-black-100 black-box-pro  ">{item.img}</div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
        {/* <div>
          <p className=" font-extralight text-base sm:text-2xl font-kanit py-8">
            • : .
          </p>

          <p className=" font-extralight text-base sm:text-2xl font-kanit py-8">
            • Drainer Deployment: Expertise is crucial for the successful
            deployment of drainers.
          </p>
          <p className=" font-extralight text-base sm:text-2xl font-kanit py-8">
            • Advanced Hacker Tactics: Professional help counters sophisticated
            techniques employed by hackers.
          </p>
          <p className=" font-extralight text-base sm:text-2xl font-kanit py-8">
            • Complex Recovery Processes: In most cases, single transactions are
            insufficient, requiring expert handling.
          </p>
          <p className=" font-extralight text-base sm:text-2xl font-kanit py-8">
            • Need for Automation: Successful recovery often necessitates
            automated systems, beyond layman capabilities.
          </p>
        </div> */}
      </div>
    </section>
  );
}

export default Professional;
