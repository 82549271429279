import React from "react";
import "./Sub.css";
// import SubImg from '../../assets/sub.png'
import Lottie from "lottie-react";
import groovyWalkAnimation from "./sub.json";

function BlogSubs() {
  return (
    <div className="container-wrapper">
      <div className="sub-box">
        <div className="sub-flex">
          <div className="sub-texts">
            <h2>Email Magnet Heading</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna{" "}
            </p>
            <form>
              <input
                type="email"
                className="input-sub"
                placeholder="Enter Email"
              />
            </form>
            <button className="submit">Submit</button>
          </div>
          <div style={{ textAlign: "end" }}>
            <Lottie
              animationData={groovyWalkAnimation}
              loop={true}
              className="sub-lottie"
            />
            {/* <img src={SubImg} className='sub-img' alt=''/> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlogSubs;
