// import Button from "components/Button";

import Lottie from "lottie-react";
import groovyWalkAnimation from "./pen.json";
import React from "react";
import { Col, Row } from "antd";

function WhatWeOffer() {
  return (
    <section className="">
      {/* <div className="flex items-center justify-between flex-col sm:flex-row gap-8"> */}

      <Row justify="center">
        <Col xxl={6} xl={9} lg={9} md={10}>
          <div className="flex justify-center items-center flex-col mt-10">
            <div>
              <h2 className=" font-space text-3xl sm:text-4xl font-bold mt-5 sm:mt-2 mbl-center">
                What is a Penetration Test?
              </h2>
              <p
                className="text-[1.3rem] mt-5 w-full font-space mbl-text mbl-center"
                style={{ fontWeight: "300" }}
              >
                A blockchain penetration test (pentest) is a simulated
                cyberattack on a blockchain network or application to identify
                security vulnerabilities and weaknesses. Pentests are performed
                in under closed environment by ethical hackers who use a variety
                of techniques to try to exploit vulnerabilities and gain
                unauthorized access to the system.
              </p>
            </div>
          </div>
        </Col>
        <Col xxl={6} xl={9} lg={9} md={10}>
          <div className="flex justify-center items-center">
            <img
              src="images/blurred.webp"
              alt="BlockAudit Blurred 3"
              className=" mr-auto absolute top-[-30px] right-0 sm:-right-[30%]"
            />
            <Lottie
              animationData={groovyWalkAnimation}
              loop={true}
              className=" flex justify-center ml-0 sm:ml-[35%]"
            />
          </div>
        </Col>
      </Row>
      {/* </div> */}
    </section>
  );
}

export default WhatWeOffer;
