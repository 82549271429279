import React from "react";

function AuditProccess() {
  return (
    <div className="container-wrapper">
      <div className="bg-proccess px-7 sm:px-32 pb-20  max-w-5xl mx-auto">
        <div className=" my-7">
          <h2 className=" text-3xl sm:text-6xl font-space text-center font-bold sm:py-8 py-4">
            Audit Process
          </h2>
        </div>
        <div>
          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 pb-12 pt-10">
            <img src="images/pen.svg" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Preparation
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                The project team provides BlockAudit with all relevant
                documentation, including the smart contract code, business
                logic, and any other technical documentation.
              </p>
            </div>
          </div>
          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className="text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Static Analysis
              </p>
              <p className=" text-left sm:text-left  font-extralight text-base sm:text-xl font-kanit">
                We use automated tools to scan the smart contract code for{" "}
                <br /> potential security vulnerabilities.
              </p>
            </div>
            <img src="images/search.svg" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/book.svg" alt="" />
            <div>
              <p className=" font-space text-center sm:text-left text-2xl font-bold pb-3 sm:pb-5 ">
                Manual Audit
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                We manually review the smart contract code line-by-line to
                identify new vulnerabilities and validate the static analysis
                report.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" sm:text-right text-center font-space text-2xl font-bold pb-3 sm:pb-5">
                Unit Testing
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                We test the smart contract to ensure that it works as intended
                and has no <br /> security vulnerabilities.
              </p>
            </div>
            <img src="images/block.svg" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/printer.svg" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Interim Reporting
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                We privately provide a report to the project team that outlines
                any security vulnerabilities found and recommendations for how
                to fix them.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Bug Fixing
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                If the BlockAudit Team finds any security vulnerabilities,
                coding errors, <br /> or inefficiencies in your smart contract,
                you must fix the code before
                <br /> deploying the contract. Our support team is available to
                assist you throughout <br /> the audit process.
              </p>
            </div>
            <img src="images/bug.svg" alt="" className=" h-[85px] sm:h-auto" />
          </div>

          <div className=" flex flex-col sm:flex-row  justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img
              src="images/reaudit.svg"
              alt="BlockAudit Reaudit"
              className=" h-[85px] sm:h-auto"
            />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Re-Audit
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                The smart contract re-audit process will begin with static
                analysis using security tools to identify potential
                vulnerabilities. This will be followed by a manual review to
                confirm the findings of the static analysis and to identify any
                additional vulnerabilities that may have been missed. Finally,
                unit tests will be performed to ensure that the smart contracts
                are functioning as expected. Once the re-audit process is
                complete, a report will be generated that details the findings
                and recommendations. We privately provide a report to the
                project team that outlines any security vulnerabilities found
                and recommendations for how to fix them.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row   justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Iteration
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                This process will be repeated until all the bugs have been fixed
                and all <br /> the vulnerabilities left from the code.
              </p>
            </div>
            <img src="images/root.svg" alt="" className=" h-[85px] sm:h-auto" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditProccess;
