import Footer from "../layouts/Footer";

// import Contact from "../layouts/Contact";
import Navbar from "../layouts/Navbar";
import Popup from "../components/Popup";
import { Helmet } from "react-helmet-async";
import Project from "components/Projects";

export default function Audit() {
  return (
    <div>
      <Helmet>
        <title>Projects</title>
        <meta
          name="description"
          content="Boost Web3 Project Confidence with Expert Blockchain Auditing Services for Comprehensive Security Reports, from Smart Contracts to DApp."
        />
        <link rel="canonical" href="https://blockaudit.report/audit-service" />
      </Helmet>
      <Navbar />
      <div className="mt-24 lg:mt-[9rem] 2xl:mt-[11rem] mb-20">
        <Project />
      </div>
      {/* <Contact /> */}
      <Footer />
      <Popup />
    </div>
  );
}
