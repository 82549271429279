import React from "react";
import "./Audit.css";
import { IKImage } from "imagekitio-react";
import Audit1 from "./Audit1";
import AllProject from "./AllProject";
import Paragraph from "components/Paragraph";

function Project() {
  return (
    <div className="container-wrapper">
      <div className="text-center mb-10">
        <Paragraph variant="2xl" className="font-bold">
          BlockAudit
        </Paragraph>
        <Paragraph variant="6xl" className="font-bold mb-6">
          Audit
        </Paragraph>
        <img
          src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_92.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662573275866"
          className="w-full"
          alt="d"
        />
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-5 lg:gap-10 text-center mb-20">
        <div className="audit-box">
          <a
            href="https://twitter.com/AncientKingNft"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/AncientKingdom.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit AncientKingdom"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Ancient Kingdom</p>
          </a>
          <div></div>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/MetaPlane_Token"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/meta.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Meta"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Meta Plane</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/SolarFarmMiner"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/Solar_Farm.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Solar Farm"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Solar Farm</p>
          </a>
        </div>

        <div className="audit-box">
          <a href="https://kickto.app/" target="_blank" rel="noreferrer">
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/kickto.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Kickto"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>KICKTO</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/FishmansLabsr"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/fml.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit FML"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>FishMan Laboratory</p>
          </a>
        </div>
        <div className="audit-box">
          <a
            href="https://twitter.com/GunHunterNFT"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/gun.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Gun"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Gun Hunter</p>
          </a>
        </div>
        <div className="audit-box">
          <a
            href="https://twitter.com/ZagentIO"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/Zagent.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Zagent"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Zagent</p>
          </a>
        </div>
        <div className="audit-box">
          <a
            href="https://twitter.com/band_royalty"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/Band_NFT.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit Band NFT"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Band NFT</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/BapeSwap"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/Bapeswap.png"}
              className="w-full h-20 object-contain mb-4"
              alt=" BlockAudit BapeSwap"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Bape Swap</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/NFTCryptotrains"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/CryptoTrains.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit CryptoTrain"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>CryptoTrains</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/limocoinswap"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/LimoCoin.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit LimoCoin"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>LimoCoin</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/99DEFIp2p"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/99DeFi.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit 99DeFi"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>99DeFi</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/SlimeNFTs"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/SlimeNFT.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit SlimeNFT"
            />

            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>SlimeNFT</p>
          </a>
        </div>
        <div className="audit-box">
          <a
            href="https://twitter.com/AstroGrowToken"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/Astrow_Grow.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit AstrowGrow"
            />

            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Astrow Grow</p>
          </a>
        </div>
        <div className="audit-box">
          <a
            href="https://twitter.com/VI_Guild"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/vi.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit VI"
            />

            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>VI Guild</p>
          </a>
        </div>

        <div className="audit-box">
          <a
            href="https://twitter.com/DysonSphereMeta"
            target="_blank"
            rel="noreferrer"
          >
            <IKImage
              lqip={{ active: true }}
              loading="lazy"
              path={"project-logo/DysonSphere.png"}
              className="w-full h-20 object-contain mb-4"
              alt="BlockAudit DysonSphere"
            />
            <img
              src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
              alt="f"
              className="w-full"
            />
            <p>Dyson Sphere</p>
          </a>
        </div>
        <Audit1 />
      </div>
      <AllProject />
    </div>
  );
}

export default Project;
