import React from "react";

function AuditProccess() {
  return (
    <div className="container-wrapper">
      <div className="bg-proccess px-7 sm:px-32 pb-20 max-w-5xl mx-auto">
        <div className=" my-7">
          <h2 className=" text-3xl sm:text-6xl font-space text-center font-bold pt-4 sm:pt-8">
            Pentesting Process
          </h2>
        </div>
        <div>
          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 pb-12 pt-10">
            <img src="images/pen_1.webp" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Preparation
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                The BlockAudit pen-testers work with the project to understand
                the scope of the pentest, including which systems and
                applications will be tested, and what kind of attacks will be
                simulated.
              </p>
            </div>
          </div>
          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className="text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Information gathering
              </p>
              <p className=" text-left sm:text-left  font-extralight text-base sm:text-xl font-kanit">
                We gather information about the blockchain network or
                application, <br />
                including its architecture, components, database, logic and{" "}
                <br /> possible vulnerabilities.
              </p>
            </div>
            <img src="images/info_1.webp" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/note_1.webp" alt="" />
            <div>
              <p className=" font-space text-center sm:text-left text-lg sm:text-xl font-bold pb-3 sm:pb-5 ">
                Vulnerability assessment
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                We use a variety of tools and techniques to identify security
                vulnerabilities in the blockchain system. This involves static
                analysis of the code, dynamic analysis of the running system,
                and manual testing.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" sm:text-right text-center font-space text-2xl font-bold pb-3 sm:pb-5">
                Exploitation
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                We attempt to exploit the vulnerabilities we have identified, to
                gain <br />
                unauthorized access to the system. This may involve using known
                exploits, <br />
                developing new exploits, or using social engineering techniques
                to trick <br />
                users into revealing sensitive information.
              </p>
            </div>
            <img src="images/pc_1.webp" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/anote.webp" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Interim Reporting
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                We privately provide a report to the project team that outlines
                any security vulnerabilities found and recommendations for how
                to fix them.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Bug Fixing
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                If the BlockAudit Team finds any security vulnerabilities,
                coding errors, or <br />
                inefficiencies in your project, you must fix the code before
                deploying the <br /> contract. Our support team is available to
                assist you throughout <br /> the pen-testing process.
              </p>
            </div>
            <img
              src="images/bug_1.webp"
              alt=" BlockAudit Bug "
              className=" w-[85px]  sm:w-auto h-[85px] sm:h-[100px] mx-auto sm:mx-0"
            />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/lock.webp" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Re-Pentesting
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                The project re-pentesting process will begin with static
                analysis using security tools to identify potential
                vulnerabilities. This will be followed by a manual review to
                confirm the findings of the static analysis and to identify any
                additional vulnerabilities that may have been missed. Once the
                re-pentesting process is complete, a report will be generated
                that details the findings and recommendations.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Iteration
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                This process will be repeated until all the bugs have been{" "}
                <br /> fixed and all the vulnerabilities left from the code.
              </p>
            </div>
            <img
              src="images/circ.webp"
              alt="BlockAudit Circ"
              className=" w-[85px]  sm:w-auto h-[85px] sm:h-[100px] mx-auto sm:mx-0"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditProccess;
