import Footer from "../layouts/Footer";
// import Contact from "../layouts/Contact";
import Navbar from "../layouts/Navbar";
import Popup from "../components/Popup";
import { Helmet } from "react-helmet-async";
import BlogPage from "components/blogPage";

function Blog() {
  return (
    <>
      <Helmet>
        <title>BlockAudit || Audit Service</title>
        <meta
          name="description"
          content="Boost Web3 Project Confidence with Expert Blockchain Auditing Services for Comprehensive Security Reports, from Smart Contracts to DApp."
        />
        <link rel="canonical" href="/blog" />
      </Helmet>
      <Navbar />
      <div className="mt-28 lg:mt-[9rem] 2xl:mt-[11rem] mb-20">
        <BlogPage />
      </div>
      {/* <Contact /> */}
      <Footer />
      <Popup />
    </>
  );
}

export default Blog;
