import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import Popup from "../components/Popup";
import EscrowPage from "components/KycPage/EscrowPage";
import { Helmet } from "react-helmet-async";
function Escrow() {
  return (
    <>
      <Helmet>
        <title>BlockAudit ||Security Consultation</title>
        <meta
          name="description"
          content="Boost Web3 Project Confidence with Expert Blockchain Auditing Services for Comprehensive Security Reports, from Smart Contracts to DApp."
        />
        <link rel="canonical" href="/consultation-service" />
      </Helmet>
      <Navbar />
      <div className="mt-28 lg:mt-[9rem] 2xl:mt-[11rem] mb-20">
        <EscrowPage />
      </div>

      <br />
      <Footer />
      <Popup />
    </>
  );
}

export default Escrow;
