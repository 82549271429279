import React from "react";
import WhyBlock from "./whyBlock";

function HighlyAccurate() {
  return (
    <>
      <div className="container-wrapper">
        <div className="pb-5 sm:pb-10 mb-5 sm:mb-10">
          <h2 className=" text-center font-space text-3xl sm:text-6xl font-bold ">
            Why BlockAudit?
          </h2>
        </div>
        <div className=" hidden sm:block max-w-5xl mx-auto">
          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/speed.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Rapid Response
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    BlockAudit swiftly responds to every <br /> case, ensuring
                    timely intervention in <br /> critical situations.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/process.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Streamlined Procedures
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    Our refined process guarantees <br /> thorough and secure
                    recovery operations.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12 sm:mb-28   ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/expert.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Unmatched Expertise
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    BlockAudit boasts a team of
                    <br /> seasoned experts skilled in the
                    <br /> navigating complex crypto security challenges. <br />
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/repo.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Trusted Leadership
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    Renowned for reliability, BlockAudit is a <br /> trusted
                    name in blockchain security solutions.
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/doller.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Optimal Efficiency
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We pride ourselves on efficient <br /> strategies that
                    maximize recovery <br /> success.
                    <br />
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/support.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Exceptional Support
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    Our dedicated customer support provides <br /> personalized
                    assistance, ensuring <br /> clarity and comfort for clients.{" "}
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" block sm:hidden">
          <WhyBlock />
        </div>
      </div>
    </>
  );
}

export default HighlyAccurate;
