import React from "react";
import { IKImage } from "imagekitio-react";

function Audit1() {
  return (
    <>
      <div className="audit-box">
        <a
          href="https://twitter.com/cryptonitroNox"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/CryptoNitro.png"}
            className="w-full h-20 object-contain mb-4"
            alt=" BlockAudit Nitro"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>CryptoNitro</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/CIH_Monster"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/CIH.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit CIH"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>CIH Monster</p>
        </a>
      </div>
      <div className="audit-box">
        <a href="https://twitter.com/AceDCoin" target="_blank" rel="noreferrer">
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/AcedCoin.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit Aced"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>AceDCoin</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/ETR_Foundation"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/eth-1.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit ETH1"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Ethereum Royalty</p>
        </a>
      </div>

      {/* second-container */}

      <div className="audit-box">
        <a href="https://twitter.com/RyuNFT" target="_blank" rel="noreferrer">
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/ryu.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit RYU"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>RyuNFT</p>
        </a>
      </div>
      <div className="audit-box">
        <a href="https://twitter.com/Van_Swap" target="_blank" rel="noreferrer">
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/Vanswap.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit VanSwap"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Vanswap</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/potent_coin"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/Potent.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit Potent"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Potent</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/GTI_token"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/green.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit Green"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Green Terra</p>
        </a>
      </div>

      {/* 3rd-conatiner */}
      <div className="audit-box">
        <a
          href="https://twitter.com/FyreCowToken"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/FyreCow.webp"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit FyreCow"
          />

          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>FyreCow</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/HamiNetwork"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/Hami_Network.png"}
            className="w-full h-20 object-contain mb-4 "
            alt="BlockAudit Hami"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Hami Network</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/Doshi_Token"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/Doshi.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit Doshi"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>Doshi</p>
        </a>
      </div>
      <div className="audit-box">
        <a
          href="https://twitter.com/EverLitToken"
          target="_blank"
          rel="noreferrer"
        >
          <IKImage
            lqip={{ active: true }}
            loading="lazy"
            path={"project-logo/EverLit.png"}
            className="w-full h-20 object-contain mb-4"
            alt="BlockAudit Everlit"
          />
          <img
            src="https://ik.imagekit.io/l4k8brcjb/BlockAudit/project-logo/Rectangle_96.png?ik-sdk-version=javascript-1.4.3&updatedAt=1662574282181"
            alt="f"
            className="w-full"
          />
          <p>EverLit</p>
        </a>
      </div>

      {/* 4th-contanier */}
      {/* <div className=''>
            <div className='audit-box'>
            <IKImage
                lqip={{ active: true }}
                loading="lazy"
                path={"project-logo/logo30.png"}
                className='img-audit'
                alt=""
              />
            </div>
            <div className='audit-box'>
            <IKImage
                lqip={{ active: true }}
                loading="lazy"
                path={"project-logo/logo31.png"}
                className='img-audit'
                alt=""
              />
            </div>
            <div className='audit-box'>
            <IKImage
                lqip={{ active: true }}
                loading="lazy"
                path={"project-logo/logo32.png"}
                className='img-audit'
                alt=""
              />
            </div>
            <div className='audit-box'>
            <IKImage
                lqip={{ active: true }}
                loading="lazy"
                path={"project-logo/logo33.png"}
                className='img-audit'
                alt=""
              />
            </div>
            </div>  */}
    </>
  );
}

export default Audit1;
