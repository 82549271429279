import { Col, Row } from "antd";
import React from "react";

function WhyAudit() {
  return (
    <>
      <div className=" ">
        <h2 className="text-center font-space text-lg sm:text-6xl font-bold pb-4 sm:pb-8">
          Why Security Consultation?
        </h2>
        <Row justify="center">
          {/* <div className=" flex justify-between flex-col sm:flex-row items-center"> */}
          <Col xxl={8} xl={10} lg={8}>
            <div className="flex justify-center items-center">
              <img
                src="images/logo-circle.webp"
                alt="BlockAudit Logo Circle"
                className=" sm:h-[350px] h-[320px] my-5 sm:mb-0"
              />
            </div>
          </Col>
          <Col xxl={8} xl={12} lg={12}>
            <div className="flex justify-center items-center  mt-[7rem]">
              <table className=" hidden sm:block ">
                {/* <thead>
          <tr>
            <th className="border-b border-gray-200">Header 1</th>
            <th className="border-b border-gray-200">Header 2</th>
          </tr>
        </thead> */}
                <tbody className=" relative">
                  <img
                    src="images/red-line.webp"
                    alt="BlockAudit Red Line"
                    className=" absolute top-[30%] bottom-0 -z-10 ml-2"
                  />
                  <tr>
                    <td className="  px-7 py-6 text-center">
                      {" "}
                      <p className=" text-[1.2rem]    font-extralight font-kanit">
                        Strategy <br /> development
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-[1.2rem]  font-extralight font-kanit">
                        Improve <br /> Security
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Reducing <br /> risks
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem]  font-extralight font-kanit">
                        Terminating <br /> Human error
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem]  font-extralight font-kanit">
                        Ensuring <br /> compliance
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem]  font-extralight font-kanit">
                        Reducing <br /> costs
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="block sm:hidden ">
                <div className=" ">
                  <ol class=" list-disc  text-red-500 bg-proccess max-w-xl w-[280px] p-4 ">
                    <li>
                      <span className=" text-sharp-red">1 . </span> Strategy
                      development
                    </li>
                    <li>
                      <span className=" text-sharp-red">2 . </span> Improve
                      Security
                    </li>
                    <li>
                      <span className=" text-sharp-red">3 . </span> Reducing
                      risks
                    </li>
                    <li>
                      <span className=" text-sharp-red">4 . </span> Terminating
                      Human error
                    </li>
                    <li>
                      <span className=" text-sharp-red">5 . </span> Ensuring
                      compliance
                    </li>
                    <li>
                      <span className=" text-sharp-red">6 . </span> Reducing
                      costs
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </Col>
          {/* </div> */}
        </Row>
      </div>
    </>
  );
}

export default WhyAudit;
