import Footer from "../layouts/Footer";
import Navbar from "../layouts/Navbar";
import Popup from "../components/Popup";
import { Helmet } from "react-helmet-async";
import DuePage from "components/KycPage/DuePage";

function DueDeli() {
  return (
    <>
      <Helmet>
        <title>BlockAudit || PenTest</title>
        <meta
          name="description"
          content="Boost Web3 Project Confidence with Expert Blockchain Auditing Services for Comprehensive Security Reports, from Smart Contracts to DApp."
        />
        <link rel="canonical" href="/pen-test" />
      </Helmet>
      <Navbar />
      <div className="mt-28 lg:mt-[9rem] 2xl:mt-[11rem] mb-200px">
        <DuePage />
      </div>
      {/* <Contact /> */}
      <Footer />
      <Popup />
    </>
  );
}

export default DueDeli;
