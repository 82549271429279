import OurWork from "components/OurWork";
import React from "react";

function Ourwork() {
  return (
   <>
    <OurWork
    title1='$3 Trillion'
    title2=' Market'
    title3=' $6 Billion'
    title4='Lost'
    title5='500+'
    title6='Attacks '
    title7=' 425 Million'
    title8='Users'
  
    />
   </>
  );
}

export default Ourwork;
