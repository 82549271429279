import React from "react";
import WhyBlock from "./whyBlock";

const Feature = ({ icon, title, description }) => (
  <div className="flex justify-between flex-col sm:flex-row gap-14 items-center">
    <img src={icon} alt={title} />
    <div>
      <h3 className="font-space text-base sm:text-2xl text-center sm:text-start font-bold mb-4">
        {title}
      </h3>
      <p
        className="font-kanit text-base sm:text-xl font-extralight text-center sm:text-start"
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
);

function HighlyAccurate() {
  return (
    <>
      <div className="container-wrapper">
        <div className="pb-5 sm:pb-10 mb-5 sm:mb-10">
          <h2 className=" text-center font-space text-3xl sm:text-6xl font-bold ">
            Why BlockAudit?
          </h2>
        </div>
        <div className=" hidden sm:block max-w-5xl mx-auto">
          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex  justify-between flex-row  items-center w-full gap-6">
              <Feature
                icon="images/watch.webp"
                title="Fast turnaround time"
                description=" BlockAudit typically completes audits <br />
            within a few weeks."
              />
              <Feature
                icon="images/blub.webp"
                title="Customizable audits"
                description="   BlockAudit can customize its <br /> audits to meet the specific needs of <br /> its clients."
              />
            </div>
          </div>

          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-row   justify-between  items-center w-full gap-6">
              <Feature
                icon="images/glob.svg"
                title=" Global reach"
                description="    BlockAudit serves clients all over <br /> the world."
              />

              <Feature
                icon="images/watch.webp"
                title="Competitive pricing"
                description=" BlockAudit offers competitive <br /> pricing for its smart
         contract audits."
              />
            </div>
          </div>

          <div className="flex justify-between items-center my-12  ">
            <div className="flex flex-row  justify-between  items-center w-full gap-6">
              <Feature
                icon="images/ear.svg"
                title="Ongoing support"
                description="  BlockAudit provides its clients <br />
            with ongoing support after <br /> the audit is complete to
            help <br /> them fix any vulnerabilities that are identified."
              />
              <Feature
                icon="images/docs.svg"
                title="   Comprehensive reporting"
                description="  BlockAudit provides its
       <br /> clients with comprehensive audit <br /> reports that
       detail all of the findings <br /> and recommendations."
              />
            </div>
          </div>
        </div>
        <div className="block sm:hidden">
          <WhyBlock />
        </div>
      </div>
    </>
  );
}

export default HighlyAccurate;
