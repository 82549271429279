// import Blog from "components/Blog";
import Subscribe from "components/Subscribe/Subscribe";
import React from "react";
import AuditProccess from "sections/AuditPage/AuditProccess";
import Hero from "sections/AuditPage/Hero";
import HighlyAccurate from "sections/AuditPage/HighlyAccurate";
import Ourwork from "sections/AuditPage/Ourwork";
import Partners from "sections/AuditPage/Partners";
// import RequestForm from "sections/AuditPage/RequestForm";
// import Sayabout from "sections/AuditPage/Sayabout";
import SmartContractAudit from "sections/AuditPage/SmartContractAudit";
import WhyAudit from "sections/AuditPage/WhyAudit";
import "./Ad.css";
import TeamHome from "components/Team/TeamHome";

function AuditPage() {
  return (
    <div className="font-inter space-y-16 lg:space-y-24">
      <div className=" pt-4 sm:pt-12 margin-top">
        <Hero />
      </div>
      {/* <div className="container-wrapper hidden lg:block">
        <hr className="text-white opacity-60" />
      </div> */}
      <div className=" py-2 sm:py-8 margin-top1">
        <Ourwork />
      </div>
      {/* <div className=" py-2 sm:py-8">
        <RequestForm />
      </div> */}
      <div className=" py-2 sm:py-8">
        <SmartContractAudit />
      </div>
      <div className=" py-2 sm:py-8">
        <WhyAudit />
      </div>

      <div className=" py-2 sm:py-8">
        <HighlyAccurate />
      </div>
      <div className=" py-2 sm:py-8">
        <AuditProccess />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          Get a quote in less than 5 hours
        </h2>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      <div className=" py-2 sm:py-8">
        <TeamHome />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          We are now online to Assist you better,
        </h2>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      <div className=" py-2 sm:py-8">
        <Partners />
      </div>
      <div className=" py-2 sm:py-8">{/* <Blog /> */}</div>
      <div className=" py-2 sm:py-8">
        <Subscribe />
      </div>
    </div>
  );
}

export default AuditPage;
