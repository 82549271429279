import React from "react";
// import { twMerge } from "tailwind-merge";

const variants = {
  sm: "text-sm",
  base: "text-sm lg:text-base",
  lg: "text-lg",
  xl: "text-lg xl:text-xl",
  "2xl": "text-xl xl:text-2xl",
  "3xl": "text-xl lg:text-2xl 2xl:text-3xl",
  "4xl": "text-2xl lg:text-3xl 2xl:text-4xl",
  "5xl": "text-5xl",
  "6xl": "text-3xl sm:text-4xl lg:text-5xl 2xl:text-6xl",
  "7xl": "text-7xl",
  "8xl": "text-8xl",
};

function Paragraph({
  as = "p",
  className,
  children,
  variant = "base",
  ...props
}) {
  const Comp = as;

  return (
    <Comp className={(" font-kanit", variants[variant], className)} {...props}>
      {children}
    </Comp>
  );
}

export default Paragraph;
