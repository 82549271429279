import IconCard from "components/IconCard";
import styles from "scss/layout/Hero.module.scss";
import Kyc from "../assets/hero/kyc.webp";
import Audit from "../assets/hero/audit.webp";
import Consult from "../assets/hero/consult.webp";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
// import useMediaQuery from "hooks/useMediaQuery";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./sub.json";
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Hero() {
  const form = useRef();
  const notify = () => toast("Thanks for filling the form. ");
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_bvudev2', 'template_ftajwwp', form.current, 'zy88WzKkbEiDztDFn')
      .then((result) => {
          console.log(result.text);
          notify()
          form.current.reset();
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <div className={styles.heroWrapper}>
      <div className="container-wrapper">
        <div className={styles.hero}>
          <main className=" relative">
            <div className=" absolute left-[9%] -top-[37%] w-full opacity-20">
              <Lottie
                animationData={groovyWalkAnimation}
                loop={true}
                className="sub-lottie w-[1065px]"
              />
            </div>
            <h1 className={`fs-50px white weight-3 lh-1 ${styles.preTitle}`}>
              Audit <span className="red">Service</span> For
            </h1>

            <Splide
              options={{
                arrows: false,
                pagination: false,
                autoWidth: false,
                gap: 30,
                autoplay: true,
                interval: 3000,
                type: "loop",
                // focus: isBellow1024px ? "center" : "center",
              }}
              className={`${styles.splideSlider} splideSlider mb-15px`}
            >
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  DApp<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  DeFi<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  GameFi<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px]lh-1 white weight-8">
                  P2E<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  Contract<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  Token<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[80px] sm:text-[100px] lh-1 white weight-8">
                  Coin<span className="red">.</span>
                </h2>
              </SplideSlide>
              <SplideSlide className="dapp-txt">
                <h2 className="text-[60px] sm:text-[80px] lh-1 white weight-8">
                  Blockchain<span className="red">.</span>
                </h2>
              </SplideSlide>
            </Splide>

            <p
              className="fs-24px white weight-2 lh-1_4 mb-30px"
              style={{ maxWidth: "22em" }}
            >
              <span className="opacity-0_6">
                Layer 1 - Layer 2 - DApp - Bridge - NFT
                <br />
              </span>{" "}
              <span className=" opacity-0_6 "></span>
            </p>

            <div
              className={`mx-auto justify-center sm:justify-start sm:mx-0 ${styles.icons}`}
            >
              <IconCard icon={Audit} title="Audit" />
              <IconCard icon={Kyc} title="PenTest" />
              <IconCard icon={Consult} title="Consult" />
            </div>
          </main>

          <aside className="mt-30px z-50">
            <div className=" bg-grand form-head ">
              <div className="text-center header-form">
                <h2 className="">Smart Contract Audit </h2>
                <p>Complete Our Quick Form for Prompt Response</p>
              </div>
              <form className="h-form" ref={form} onSubmit={sendEmail}>
                <input
                  type="email"
                  className="fs-18px white text-black-100 weight-3"
                  placeholder="Email"
                  name="to_email"
                />
                <input
                  type="text"
                  className="fs-18px white weight-3 text-black-100 bg-white-100"
                  placeholder="Telegram"
                  name="to_tele"
                />
                <input
                  type="text"
                  className="fs-18px white text-black-100 weight-3"
                  placeholder="Source Code URL"
                  name="to_code"
                />

                <div className="btn-modal">
                  <button
                    type="submit"
                    value="Send"
                    className="fs-26px white weight-5 pointer send-btn"
                  >
                    Submit
                  </button>
                </div>
              </form>
              <ToastContainer
           position="bottom-center"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              className='z-[9999]'
              theme="dark"
               />
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
}

export default Hero;
