import { Col, Row } from "antd";
import React from "react";

function SmartContractAudit() {
  return (
    <>
      <div className="   ">
        {/* <div className="flex items-center justify-between flex-col sm:flex-row gap-2"> */}
        {/* <div className=""> */}
        <Row justify="center">
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center flex-col">
              <div>
                <h2 className=" font-space text-3xl sm:text-2xl font-bold   sm:text-start mt-5 sm:mt-2 mbl-center">
                  What is a Smart Contract Audit?
                </h2>
                <p
                  className="text-[1.3rem] mt-5 w-full font-space mbl-center mbl-text"
                  style={{ fontWeight: "300" }}
                >
                  A smart contract audit entails a comprehensive examination of
                  on-chain code, emphasising smart contracts, to validate their
                  operational integrity and absence of security flaws.
                  Considering the immutable characteristics of blockchain and
                  the potential magnitude of financial interactions via smart
                  contracts, the assurance of their security and accuracy is of
                  utmost importance.
                </p>
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center">
              <img
                src="images/blurred.webp"
                alt=""
                className=" mr-auto absolute top-0 right-0 sm:-right-[40%]"
              />
              <img
                src="images/Audit.gif"
                alt=""
                className=" h-[290px] sm:h-[300px]  ml-0  sm:ml-[40%]"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default SmartContractAudit;
