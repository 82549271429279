import React from "react";
import "./Footer.css";
import {
  BsTwitter,
  BsLinkedin,
  BsTelegram,
  BsGithub,
  BsMedium,
} from "react-icons/bs";
import Logo from "../assets/logo.webp";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="container-wrapper">
      <div className="border-top-grad"></div>
      <div className="footer desk-foot">
        <div>
          <div className="logo-section">
            <img src={Logo} alt="BlockAudit Grad" />
            <p>
              Secure your web3 future with BlockAudit's expert <br /> solutions.
            </p>
            <a href="mailto:support@blockaudit.report" className="white ">
              support@blockaudit.report
            </a>
          </div>
        </div>
        <div>
          <div className="social-section">
            <h2>Social Media</h2>
            <div>
              <p>
                <a
                  href="https://twitter.com/BlockAudit"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-white-100"
                >
                  <BsTwitter className="inline-block " /> Twitter
                </a>{" "}
              </p>

              <p>
                <a
                  href="https://www.linkedin.com/company/block-audit-report"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-white-100 pointer"
                >
                  <BsLinkedin className="inline-block" /> LinkedIn
                </a>
              </p>

              <p>
                {" "}
                <a
                  href="https://t.me/BlockAuditReport"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-white-100 pointer"
                >
                  <BsTelegram className="inline-block" /> Telegram
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://blockauditreport.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-white-100 pointer"
                >
                  <BsMedium className="inline-block" /> Medium
                </a>
              </p>
              <p>
                <a
                  href="https://github.com/Block-Audit-Report"
                  target="_blank"
                  rel="noreferrer"
                  className="hover:text-white-100 pointer"
                >
                  <BsGithub className="inline-block" /> Github
                </a>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="social-section">
            <h2>Services</h2>
            <div>
              <Link to="/audit-service">
                <p className="hover:text-white-100 pointer">Audit</p>
              </Link>
              <Link to="/pen-test">
                <p className="hover:text-white-100 pointer">Pen Testing</p>
              </Link>
              <Link to="/fund-recovery">
                <p className="hover:text-white-100 pointer">
                  Fund Recovery
                </p>
              </Link>
              <Link to="/consultation-service">
                <p className="hover:text-white-100 pointer">Consulting</p>
              </Link>
              <p className="hover:text-white-100 pointer"></p>
            </div>
          </div>
        </div>

        <div>
          <div className="social-section">
            <h2>Our Policies</h2>

            <div className="">
              <Link to="/privacy-policy">
                {" "}
                <p className="hover:text-white-100 pointer">Privacy Policy</p>
              </Link>

              <Link to="/disclaimer">
                <p className="hover:text-white-100 pointer">Disclaimer </p>
              </Link>

              <Link to="/terms-condition">
                <p className="hover:text-white-100 pointer">T & C </p>
              </Link>

              <Link to="/cookies">
                <p className="hover:text-white-100 pointer">Cookies Policy</p>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="footer mobile-foot">
        <div>
          <div className="logo-section">
            <img src={Logo} alt="BlockAudit Foot" />
            <p>
              Secure your web3 future with BlockAudit's expert <br /> solutions.
            </p>
            <a href="mailto:support@blockaudit.report" className="white ">
              support@blockaudit.report
            </a>

            <div className="mob-social">
              <p>
                {" "}
                <a
                  href="https://t.me/BlockAuditReport"
                  target="_blank"
                  rel="noreferrer"
                  className="pointer"
                >
                  <span>
                    {" "}
                    <BsTelegram />
                  </span>{" "}
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://www.linkedin.com/company/block-audit-report"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <span>
                    <BsLinkedin />{" "}
                  </span>{" "}
                </a>
              </p>
              <p>
                <a
                  href="https://twitter.com/BlockAudit"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <BsTwitter />{" "}
                  </span>{" "}
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://github.com/Block-Audit-Report"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <BsGithub />{" "}
                  </span>{" "}
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://blockauditreport.medium.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <span>
                    <BsMedium />{" "}
                  </span>
                </a>{" "}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="social-section">
            <h2>Services</h2>
            <div>
              <p>Audits</p>
              <p>Pen Testing</p>
              <p>Fund Recovery</p>
              <p>Consulting</p>
            </div>
          </div>
        </div>

        <div>
          <div className="social-section">
            <h2>Our Policies</h2>

            <div className="">
              <Link to="/privacy-policy">
                {" "}
                <p className="">Privacy Policy</p>
              </Link>

              <Link to="/disclaimer">
                <p className="">Disclaimer </p>
              </Link>

              <Link to="/terms-condition">
                <p className="">T & C </p>
              </Link>

              <Link to="/cookies">
                <p className="">Cookies Policy</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="grey-grand"></div>
      <div className="copyright">
        <p>Copyright 2024 | All Rights Reserved</p>
        <p>
          Secured By:{" "}
          <span
            className=""
            style={{ color: "#fff", fontFamily: "Space Grotesk" }}
          >
            BlockAudit
          </span>{" "}
        </p>
      </div>
    </div>
  );
}

export default Footer;
