import React from "react";
import Audit from "../../assets/audit.webp";
import Kyc from "../../assets/kyc.webp";
import Consult from "../../assets/consult.webp";
import Secure from "../../assets/secure.webp";

function AboutSection() {
  return (
    <main id="about">
      <section>
        <div className="container-wrapper">
          <div
            className="about-box gradient-box "
            style={{ position: "relative" }}
          >
            <div className="text-center ab-head">
              <h2 className="text-[3rem]">About Us</h2>
            </div>
            <div className="flex-about mb-50px">
              <div className="d-about">
                <p>
                  Our personalised approach to client service, coupled with our
                  dedication to delivering exceptional results, sets us apart as
                  a trusted partner in the rapidly-evolving web3 ecosystem.{" "}
                </p>
                <p>
                  Partner with BlockAudit to take your web3 project to the next
                  level and achieve unparalleled security and compliance.
                </p>

                <a href="https://t.me/BlockAuditReport">
                  <h4 className="reach"> Let's Talk </h4>
                </a>
              </div>
              <div className="auit-box">
                <div className="a-box">
                  <div>
                    <img src={Audit} alt="new" />
                  </div>
                  <div className="detail-box">
                    <h3>400+</h3>
                    <p>Audits</p>
                  </div>
                </div>

                <div className="a-box">
                  <div>
                    <img src={Kyc} alt="new" />
                  </div>
                  <div className="detail-box">
                    <h3>85+</h3>
                    <p>KYC</p>
                  </div>
                </div>

                <div className="a-box">
                  <div>
                    <img src={Consult} alt="new" />
                  </div>
                  <div className="detail-box">
                    <h3>50+</h3>
                    <p>Consultations</p>
                  </div>
                </div>

                <div className="a-box">
                  <div>
                    <img src={Secure} alt="new" />
                  </div>
                  <div className="detail-box">
                    <h3>1B $+</h3>
                    <p>Secured</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="side-grid"></div>
        <div className="side-grid-right"></div>
      </section>
    </main>
  );
}

export default AboutSection;
