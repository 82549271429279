import React, { useEffect, useState } from "react";
import "./Blog.css";
import styles from "scss/layout/Business.module.scss";
import useMediaQuery from "hooks/useMediaQuery";
// import { RightCircleOutlined } from "@ant-design/icons";
import { Col, Row, Card } from "antd";
import blog1 from "../../assets/blog1.webp";
import blog2 from "../../assets/blog2.webp";
import blog3 from "../../assets/blog3.webp";
// var moment = require("moment");

function AllBlog() {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const data = [
    {
      id: 1,
      image: <img src={blog1} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 2,
      image: <img src={blog2} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 3,
      image: <img src={blog3} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 4,
      image: <img src={blog1} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },

    {
      id: 5,
      image: <img src={blog2} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 6,
      image: <img src={blog3} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 7,
      image: <img src={blog1} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 8,
      image: <img src={blog2} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 9,
      image: <img src={blog3} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 10,
      image: <img src={blog1} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 11,
      image: <img src={blog2} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
    {
      id: 12,
      image: <img src={blog3} alt="abc" className="img-size" />,
      button: "HACK ANALYSIS",
      title: "Simplify ERC-4337 transaction signing",
    },
  ];
  useEffect(() => {
    fetch("https://dehacks-newsletter.beehiiv.com/")
      .then((res) => res.json())
      .then((response) => {
        setMediumData(response);
        setIsLoading(false);
        console.log(response, "data"); // Log the response to check for data structure
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  // let toText = (node) => {
  //   let tag = document?.createElement("div");
  //   tag.innerHTML = node;
  //   node = tag.innerText;
  //   return node;
  // };
  //   const finalData = mediumData.slice(0, 3);
  const isBellow500px = useMediaQuery("(max-width : 31.25em)");
  return (
    <>
      <div className="container-wrapper">
        <div className={styles.wrapper}>
          <header
            className={isBellow500px ? "mb-20px " : "mb-120px mt-120px r"}
          >
            <div className="container-wrapper pad ">
              <h1 className="text-center fs-64px white weight-6 lh-1 font-space">
                BLOG
              </h1>
            </div>
          </header>
        </div>
      </div>
      <Row justify="center">
        {data.map((item, index) => (
          <Col key={index} xxl={7} xl={7} lg={7} md={10}>
            <div className="flex justify-center items-center flex-col mt-10 mb-10 ">
              <Card
                hoverable
                style={{
                  background: "black",
                  border: "solid 1px black",
                }}
                cover={item.image}
                className="hovr-card"
              >
                {/* <img src={blog1} alt="abc" /> */}

                <div className="mt-2 ">
                  <button className="btn-black ml-2 text-white-100">
                    {item.button}
                  </button>
                  {/* <button className="btn-black1 ml-4 text-white-100">
                    blog
                  </button> */}
                  <p className="mt-2   text-white-100">{item.title} </p>
                </div>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      {/* <div id="blog" className="container-wrapper mt-3 ">
        {isLoading && <p>Fetching data from Medium!</p>}

        <div className="news-box">
          {finalData.map((article) => (
            <div md="4" className="   news-card" key={article.guid}>
              <div>
                <div className="img-b">
                  <img top width="100%" src={article.thumbnail} alt="img" />
                </div>
                <div>
                  <div className="news-text">
                    <a href={article.link}>
                      <h2>
                        {" "}
                        {toText(article?.title).substring(0, 30).concat("...")}
                      </h2>
                    </a>
                  </div>
                   <p className='news-text-p'>{toText(article?.content).substring(0, 70).concat("...")}{" "}</p>
                    
                </div>
              </div>
            </div>
          ))}
          <div className="text-center mt-2">
            <a href="https://blockauditreport.medium.com/">
              <RightCircleOutlined className="size-icon" />
            </a>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default AllBlog;
