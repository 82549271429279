import { Swiper, SwiperSlide } from "swiper/react";
// import CotImg from "../../assets/about/cot.png";
// import DefiImg from "../../assets/about/99defi.jpg";
// import PttImg from "../../assets/about/potent.jpg";
// import AcedImg from "../../assets/about/aced.jpg";
import CotImg from "../../assets/about/cot.webp";
import AcedImg from "../../assets/x__twitter.webp";
import DefiImg from "../../assets/twit.webp";
import PttImg from "../../assets/menti.webp";
import DEFI from "../../assets/about/99defi.webp";
import AceDCoin from "../../assets/about/aced.webp";
import Potent from "../../assets/about/potent.webp";
import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Autoplay,
} from "swiper/modules";
import { useState } from "react";

export default function TeamHomeMobo() {
  const [swiper, setSwiper] = useState(null);

  const handleMouseEnter = () => {
    if (swiper) {
      swiper.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiper) {
      swiper.autoplay.start();
    }
  };
  return (
    <>
      <Swiper
        onSwiper={setSwiper}
        navigation={true}
        loop={true}
        modules={[Navigation, EffectCoverflow, Pagination, Autoplay]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box">
            <img src={CotImg} alt="" className="colon" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit team is knowledgeable, responsive, and committed to
                security and risk management.
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                After a phishing attack hit my wallet, I was in a real panic.
                The team quickly dealt with the drainer issue and managed my
                unstaking tokens brilliantly. Their constant updates and
                invaluable security tips were a huge relief. I've completely
                trusted them ever since and recommended their excellent service
                to many friends. Truly grateful for BlockAudit's lifesaving
                help.
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={DefiImg} alt="" />
              </div>
              <div>
                <a
                  href="https://twitter.com/Momoyuumi25"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="user-name">MOMO</p>
                  <p className="user-social">@Momoyuumi25</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box">
            <img src={CotImg} alt="" className="colon" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit exceeded our expectations in providing exceptional
                Smart Contract Auditing and Risk Management services.
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit was a game changer when I lost access to my crypto.
                They were experts at recovery and got my assets back safely with
                their advanced methods and constant watch. I loved that I only
                paid after getting my funds back. Their audit and consulting
                services were top-notch too. Definitely recommend them for
                anyone in a similar spot!
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={PttImg} alt="" />
              </div>
              <div>
                <a
                  href="https://twitter.com/MendeMatthias"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="user-name">Mende</p>
                  <p className="user-social">@MendeMatthias</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box">
            <img src={CotImg} alt="" className="colon" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                The team at BlockAudit consists of professionals with extensive
                skills and experience who are committed to delivering
                exceptional smart contract audit services
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                When my MetaMask wallets were compromised, the exceptional team
                at "Akshay" provided invaluable support. With their continuous
                guidance and practical actions, they helped recover as much as
                possible of what was at risk. Their swift and dedicated efforts
                ensured the maximum recovery of funds, demonstrating strong
                commitment not limited to time or resources
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={AcedImg} alt="" style={{ background: "white" }} />
              </div>
              <div>
                <a href="https://twitter.com" target="_blank" rel="noreferrer">
                  <p className="user-name">Anonymous</p>

                  <p className="user-social">@Anonymous</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* 99DEFI */}
        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box">
            <img src={CotImg} alt="" className="colon z-50" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit team is knowledgeable, responsive, and committed to
                security and risk management
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                They are reliable, and efficient, and have been a valuable
                partner for many teams seeking smart contract audit services. I
                highly recommend BlockAudit to anyone looking for a top-tier
                firm to ensure the security and success of their web3 project.
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={DEFI} alt="" />
              </div>
              <div>
                <a
                  href="https://twitter.com/99DEFIp2p"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="user-name">99DEFI</p>
                  <p className="user-social">@99DEFIp2p</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* AceDCoin */}
        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box relative z-50">
            <img src={CotImg} alt="" className="colon z-50" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit team is knowledgeable, responsive, and committed to
                security and risk management.
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                The team at BlockAudit consists of professionals with extensive
                skills and experience who are committed to delivering
                exceptional smart contract audit services. Their work reflects
                their dedication to excellence, and they offer excellent
                communication, support, and responsiveness, making them an ideal
                partner for any team in need of smart contract audit services
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={AceDCoin} alt="" />
              </div>
              <div>
                <a
                  href="https://twitter.com/AceDCoin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="user-name">AceDCoin</p>
                  <p className="user-social">@AceDCoin</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
        {/* potent_coin */}
        <SwiperSlide
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="team-box">
            <img src={CotImg} alt="" className="colon z-50" />
            <div className="team-details">
              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                The team at BlockAudit consists of professionals with extensive
                skills and experience who are committed to delivering
                exceptional smart contract audit services
              </p>

              <p className="  font-kanit text-sm sm:text-xl font-extralight">
                BlockAudit provided me with an outstanding audit report for my
                smart contract. The report was well-structured, and thorough,
                and gave precise suggestions for reducing security risks. Its
                in-depth examination of potential weaknesses helped me to detect
                and resolve any problems that might have jeopardized the
                security and prosperity of my web3 venture.
              </p>
            </div>
            <div className="avatar-flex">
              <div>
                <img src={Potent} alt="" style={{ background: "white" }} />
              </div>
              <div>
                <a
                  href="https://twitter.com/potent_coin"
                  target="_blank"
                  rel="noreferrer"
                >
                  <p className="user-name">PotentCoin</p>

                  <p className="user-social">@potent_coin</p>
                </a>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
