import Hero_main from "components/Hero_main";
import Paragraph from "components/Paragraph";

import React from "react";

function Hero() {
  return (
    <>
      <Hero_main
        Title="Blockchain Security Consultation"
        Para="It’s a service that includes identifying and assessing  security risks, developing and implementing security  solutions, and monitoring blockchain networks for  suspicious activity."
        ButtonTitle="Enquire Now  "
        Href="https://t.me/BlockAuditReport"
      />
    </>
  );
}

export default Hero;
