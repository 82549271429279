import React from "react";
import WhyAudit from "./WhyAudit";
import WhyBlock from "./whyBlock";

function HighlyAccurate() {
  return (
    <>
      <div className="container-wrapper">
        <div className="pb-5 sm:pb-10 mb-5 sm:mb-10">
          <h2 className=" text-center font-space text-3xl sm:text-6xl font-bold ">
            Why BlockAudit?
          </h2>
        </div>
        <div className=" hidden sm:block max-w-5xl mx-auto">
          <div className="flex justify-between items-center my-12  sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/start.svg" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Experience
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We have a team of experienced <br /> security professionals
                    who have <br /> consulted on 50+ projects on <br /> web3.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/timer2.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Proven Track Record
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We have a proven track record of <br /> helping
                    organizations improve the <br />
                    security of their blockchain <br /> environments and assets.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12  sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/web.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Global Reach
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    BlockAudit has a global reach, <br />
                    with clients from all over the <br />
                    world, and helped 50+ companies <br />
                    towards better security.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/verify2.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Commitment to Quality
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We are committed to providing our <br />
                    clients with the highest quality <br />
                    blockchain security consultation <br />
                    services. We are up-to-date on the <br />
                    latest blockchain security threats <br /> and mitigation
                    techniques.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12  sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/user2.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Customer-centric Approach
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We take a customer-centric approach <br /> to blockchain
                    security consultation. <br />
                    They work closely with their clients to <br />
                    understand their specific needs and <br />
                    develop tailored solutions to meet <br />
                    those needs.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/chatt.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Detailed Plan
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We provide detailed plans for <br />
                    security management that clearly <br />
                    explain the approach to mitigate <br />
                    vulnerabilities and reduce risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" block sm:hidden">
          <WhyBlock />
        </div>
      </div>
    </>
  );
}

export default HighlyAccurate;
