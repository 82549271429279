import React from "react";
import WhyBlock from "./whyBlock";

function HighlyAccurate() {
  return (
    <>
      <div className="container-wrapper">
        <div className="pb-5 sm:pb-10 mb-5 sm:mb-10">
          <h2 className=" text-center font-space text-3xl sm:text-6xl font-bold ">
            Why BlockAudit?
          </h2>
        </div>
        <div className=" hidden sm:block max-w-5xl mx-auto">
          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/start.svg" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Experience
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We have a team of experienced security <br />
                    professionals who have conducted hundreds <br />
                    of smart contract audits and penetration tests.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/bulb_1.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Expertise
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    Our team has a deep <br />
                    understanding of blockchain <br /> technology and the latest{" "}
                    <br /> vulnerabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-14 items-center">
                <img src="images/time_1.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Track record
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We have a proven track record <br /> of identifying and
                    mitigating <br /> security risks in blockchain
                    <br /> projects.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/repo.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Reputation
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    BlockAudit is a well-respected <br /> company in the
                    blockchain <br /> security industry.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-between items-center my-12 sm:mb-28  ">
            <div className="flex flex-col justify-between sm:flex-row items-center w-full gap-6">
              <div className=" flex justify-between gap-10 items-center">
                <img src="images/youtube.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Comprehensive services
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We offer a comprehensive suite <br /> of pen-testing
                    services, <br />
                    including manual and <br /> automated testing, as well as{" "}
                    <br />
                    social engineering assessments.
                  </p>
                </div>
              </div>

              <div className=" flex justify-between gap-14 items-center">
                <img src="images/doc_1.webp" alt="" />
                <div>
                  <h3 className=" font-space text-base sm:text-2xl font-bold mb-4">
                    Detailed reports
                  </h3>
                  <p className="  font-kanit text-sm sm:text-xl font-extralight">
                    We provide detailed reports that <br /> clearly explain the
                    vulnerabilities <br /> that were found <br /> and how to fix
                    them.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" block sm:hidden">
          <WhyBlock />
        </div>
      </div>
    </>
  );
}

export default HighlyAccurate;
