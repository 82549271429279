import React from "react";
// import Paragraph from "./Paragraph";
import Lottie from "lottie-react";
import backgroundLine from "./Background Lines";
import "./Blog.css";
import { Col, Row } from "antd";
function HeroSection() {
  return (
    <>
      <div className=" absolute  -top-[37%] w-full opacity-20 flex justify-center items-center -z-100">
        <Lottie
          animationData={backgroundLine}
          loop={true}
          className="sub-lottie w-full "
        />
      </div>
      {/* <section className="    "> */}
      {/* <div className=""> */}
      <main className=" relative flex justify-center items-center container ">
        {/* <section className="items-center gap-8 grid grid-cols-1 sm:grid-cols-2 justify-between relative "> */}
        <Row justify="center">
          <Col xxl={12} xl={11} lg={11}>
            <div className="flex justify-center items-center flex-col">
              <div>
                <h1 className="font-bold lh-1_1 mb-4 text-4xl sm:text-7xl font-space ">
                  Weekly-Blog
                </h1>
                <h4 className="font-bold lh-1_1 mb-4 text-2xl sm:text-3xl font-space ">
                  {" "}
                  Read, Learn, And Be Updated!!!
                </h4>
                <p className="opacity-80 mb-8 text-base sm:text-2xl mt-2">
                  BlockAudit’s Weekly Blog Empower Web 3.0's Future and Yours!
                </p>
              </div>
            </div>
          </Col>
          <Col xxl={12} xl={11} lg={11}>
            <div className="flex justify-center items-center flex-col">
              <img
                src="images/Frame.webp"
                alt="img"
                className="img-frame z-50   "
              />
              {/* <img
                src="images/Frame1.webp"
                alt=""
                className=" -z-50  opacity-100  absolute"
              /> */}
            </div>
          </Col>
        </Row>
        {/* </section> */}
      </main>
      {/* </div> */}
      {/* </section> */}
    </>
  );
}

export default HeroSection;
