import React from "react";

function Partners() {
  return (
    <div className="container-wrapper ">
      <div>
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold py-2 sm:py-6">
          Partners
        </h2>
      </div>
      <div
        className="grid
 grid-cols-2 sm:grid-cols-4 gap-8 my-8"
      >
        <img src="images/partner/img1.webp" alt="" />
        <img src="images/partner/img2.webp" alt="" />
        <img src="images/partner/img3.webp" alt="" />
        <img src="images/partner/img4.webp" alt="" />
        <img src="images/partner/img5.webp" alt="" />
        <img src="images/partner/img6.webp" alt="" />
        <img src="images/partner/img7.webp" alt="" />
        <img src="images/partner/img8.webp" alt="" />
        <img src="images/partner/img9.webp" alt="" />
        <img src="images/partner/img10.webp" alt="" />
        <img src="images/partner/img11.webp" alt="" />
        <img src="images/partner/img12.webp" alt="" />
      </div>
    </div>
  );
}

export default Partners;
