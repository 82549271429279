import React from "react";
import "./Partner.css";
function OurWork({
  title1,
  title2,
  title3,
  title4,
  title5,
  title6,
  title7,
  title8,
}) {
  return (
    <div className="container-wrapper ">
      {/* <div className=" mb-8 sm:mb-16">
      <h1 className=" font-space font-bold text-4xl text-center sm:text-6xl sm:pb-6 pb-3 ">
        Our Work
      </h1>
    </div> */}

      <div className="flex justify-around items-center mx-auto work-box py-2">
        <div className=" flex items-center justify-between">
          <h2 className=" font-kanit font-light   sm:text-2xl text-center work-font">
            {title1} <br /> {title2}
          </h2>
        </div>
        <img src="images/side-bar.webp" alt="" className="mbl-size" />
        <div className=" flex items-center justify-between">
          <h2 className=" font-kanit font-light text-sm sm:text-2xl text-center work-font">
            {title3} <br /> {title4}
          </h2>
        </div>
        <img src="images/side-bar.webp" alt="" className="mbl-size" />
        <div>
          <h2 className=" font-kanit font-light text-sm sm:text-2xl text-center work-font">
            {title5} <br /> {title6}
          </h2>
        </div>
        <img src="images/side-bar.webp" alt="" className="mbl-size" />
        <div>
          <h2 className=" font-kanit font-light text-sm sm:text-2xl text-center work-font">
            {title7} <br /> {title8}
          </h2>
        </div>
      </div>
    </div>
  );
}

export default OurWork;
