import Hero_main from "components/Hero_main";

import React from "react";

function Hero() {
  return (
    <>
      <Hero_main
        Title="Blockchain Penetration Testing"
        Para="A thorough evaluation of the security of your smart contract
     and DApps, aimed at pinpointing any weaknesses and
     proposing solutions to address them."
        ButtonTitle="Request a quote"
        Href="https://t.me/BlockAuditReport"
      />
    </>
  );
}

export default Hero;
