import { Col, Row } from "antd";
import React from "react";

function HowWeDiffer() {
  return (
    <>
      <div className=" ">
        <h2 className="text-center font-space text-lg sm:text-6xl font-bold">
          Why Pentest?
        </h2>
        <Row justify="center">
          {/* <div className=" flex justify-between flex-col sm:flex-row items-center"> */}
          <Col xxl={8} xl={10} lg={8}>
            <div className="flex justify-center items-center">
              <div>
                <img
                  src="images/logo-circle.webp"
                  alt="BlockAudit Circle 11"
                  className="sm:h-[350px] h-[320px] my-5 sm:my-2"
                />
              </div>
            </div>
          </Col>
          <Col xxl={8} xl={12} lg={12}>
            <div className="flex justify-center items-center  mt-[7rem]">
              <table className=" hidden sm:block ">
                {/* <thead>
          <tr>
            <th className="border-b border-gray-200">Header 1</th>
            <th className="border-b border-gray-200">Header 2</th>
          </tr>
        </thead> */}
                <tbody className=" relative">
                  <img
                    src="images/red-line.webp"
                    alt="BlockAudit Line 3"
                    className=" absolute top-[20%] bottom-0 -z-10 ml-3"
                  />
                  <tr>
                    <td className="  px-7 py-7 text-center">
                      {" "}
                      <p className=" text-[1.2rem]   font-extralight font-kanit">
                        Identifying <br /> vulnerabilities
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Increase trust <br /> & confidence
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Reducing <br /> risks
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Improving <br /> compliance
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Improve security <br /> posture
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-[1.2rem] font-extralight font-kanit">
                        Comply with <br /> regulations
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="block sm:hidden ">
                <div className=" ">
                  <ol class=" list-disc  text-red-500 bg-proccess max-w-xl w-[280px] p-4 ">
                    <li>
                      <span className=" text-sharp-red">1 . </span> Identifying
                      vulnerabilities
                    </li>
                    <li>
                      <span className=" text-sharp-red">2 . </span> Increase
                      trust & confidence
                    </li>
                    <li>
                      <span className=" text-sharp-red">3 . </span> Increase
                      trust & confidence
                    </li>
                    <li>
                      <span className=" text-sharp-red">4 . </span> Reducing
                      risks
                    </li>
                    <li>
                      <span className=" text-sharp-red">5 . </span> Improve
                      security posture
                    </li>
                    <li>
                      <span className=" text-sharp-red">6 . </span> Comply with
                      regulations
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </Col>
          {/* </div> */}
        </Row>
      </div>
    </>
  );
}

export default HowWeDiffer;
