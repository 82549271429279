import SwiperCoverflow from 'components/Team/Team'
import React from 'react'

function Sayabout() {
  return (
    <div>
        <div>
            <SwiperCoverflow/>
        </div>
    </div>
  )
}

export default Sayabout