import { Col, Row } from "antd";
import React from "react";

function WhyAudit() {
  return (
    <>
      <div className=" ">
        <h2 className="text-center font-space text-2xl sm:text-6xl font-bold">
          Why Audit?
        </h2>
        <Row justify="center">
          {/* <div className=" flex justify-between flex-col sm:flex-row items-center"> */}
          <Col xxl={8} xl={10} lg={8}>
            <div className="flex justify-center items-center">
              <img
                src="images/logo-circle.webp"
                alt=""
                className=" sm:h-[350px] h-[320px] my-5 sm:mb-0"
              />
            </div>
          </Col>
          <Col xxl={8} xl={12} lg={12}>
            <div className="flex justify-center items-center  mt-[7rem]">
              <table className=" hidden sm:block ">
                {/* <thead>
          <tr>
            <th className="border-b border-gray-200">Header 1</th>
            <th className="border-b border-gray-200">Header 2</th>
          </tr>
        </thead> */}
                <tbody className=" relative">
                  <img
                    src="images/red-line.webp"
                    alt=""
                    className=" absolute top-[15%] bottom-0 -z-10 "
                  />
                  <tr>
                    <td className="  px-7 py-6 text-center">
                      {" "}
                      <p className=" text-lg   font-extralight font-kanit">
                        Better code <br /> quality
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-lg font-extralight font-kanit">
                        Improved <br /> security
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      {" "}
                      <p className=" text-lg font-extralight font-kanit">
                        Protects users <br /> and assets
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-lg font-extralight font-kanit">
                        Reduced <br /> risk
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-lg font-extralight font-kanit">
                        Improve security <br /> posture
                      </p>
                    </td>
                    <td className=" px-7 py-6 text-center">
                      <p className=" text-lg md:text:md font-extralight font-kanit">
                        Preventing terrorist <br /> financing
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="block sm:hidden ">
                <div className=" ">
                  <ol class=" list-disc  text-red-500 bg-proccess max-w-xl w-[280px] p-4 ">
                    <li>
                      <span className=" text-sharp-red">1 . </span> Better code
                      quality
                    </li>
                    <li>
                      <span className=" text-sharp-red">2 . </span> Improved
                      security
                    </li>
                    <li>
                      <span className=" text-sharp-red">3 . </span> Protects
                      users and assets
                    </li>
                    <li>
                      <span className=" text-sharp-red">4 . </span> Reduced risk
                    </li>
                    <li>
                      <span className=" text-sharp-red">5 . </span> Improve
                      security posture
                    </li>
                    <li>
                      <span className=" text-sharp-red">6 . </span> Improve
                      security posture
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </Col>
          {/* </div> */}
        </Row>
      </div>
    </>
  );
}

export default WhyAudit;
