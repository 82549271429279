import OurWork from "components/OurWork";
import React from "react";

function Ourwork() {
  return (
    <>
      <OurWork
        title1="350+ "
        title2="Audits"
        title3=" $1 Billion   "
        title4="Secured"
        title5="400K+ lines of "
        title6=" Code Audited "
        title7="4+ Years "
        title8=" Serviced"
      />
    </>
  );
}

export default Ourwork;
