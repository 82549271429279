// import Blog from "components/Blog";

import TeamHome from "components/Team/TeamHome";
import React from "react";
import Partners from "sections/KYC/Partners";
// import RequestForm from "sections/KYC/RequestForm";
// import Sayabout from "sections/KYC/Sayabout";
// import Terms from "sections/KYC/Terms";
import AuditProccess from "sections/Management/AuditProccess";
import DocumentNeeded from "sections/Management/DocumentNeeded";
import Hero from "sections/Management/Hero";
import HighlyAccurate from "sections/Management/HighlyAccurate";
import Ourwork from "sections/Management/Ourwork";
import SmartContractAudit from "sections/Management/SmartContractAudit";
import WhyAudit from "sections/Management/WhyAudit";

function EscrowPage() {
  return (
    <div className="space-y-16 lg:space-y-28">
      <div className="pt-4 sm:pt-12 margin-top">
        <Hero />
      </div>
      <div className=" py-2 sm:py-8 margin-top1">
        <Ourwork />
      </div>
      {/* <div className=" py-2 sm:py-8">
      <RequestForm/>
      </div> */}
      <div className=" py-2 sm:py-8">
        <SmartContractAudit />
      </div>
      <div className=" py-2 sm:py-8">
        <WhyAudit />
      </div>
      <div className=" py-2 sm:py-8">
        <HighlyAccurate />
      </div>
      <div className=" py-2 sm:py-8">
        <AuditProccess />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          Get connected with Experts,
        </h2>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Ask Expert Now
          </a>
        </div>
      </div>
      <div className=" py-2 sm:py-8">
        <Partners />
      </div>
      <div className=" py-2 sm:py-8">
        <DocumentNeeded />
      </div>
      <div className=" py-2 sm:py-8">
        <TeamHome />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          We are now online to Assist you better,
        </h2>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request to recover funds now.
          </a>
        </div>
      </div>
      {/* <div className=" py-2 sm:py-8">
        <Terms/>

      </div> */}
      {/* <Blog /> */}
    </div>
  );
}

export default EscrowPage;
