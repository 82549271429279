import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Autoplay,
} from "swiper/modules";

const Feature = ({ icon, title, description }) => (
  <div className="flex justify-between flex-col sm:flex-row gap-4 items-center h-full">
    <img src={icon} alt={title} />
    <div>
      <h3 className="font-space text-base  text-center  font-bold mb-1">
        {title}
      </h3>
      <p
        className="font-kanit text-sm  font-extralight text-center "
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
);

export default function WhyBlock() {
  return (
    <>
      <Swiper
        navigation={true}
        loop={true}
        modules={[Navigation, EffectCoverflow, Pagination, Autoplay]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/start.svg"
              title="Experience"
              description="We have a team of experienced
              security professionals who have
              consulted on 50+ projects on
              web3."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/timer2.webp"
              title="Proven Track Record"
              description=" We have a proven track record of
              helping organizations improve the
              security of their blockchain
              environments and assets."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/web.webp"
              title="Global Reach"
              description=" BlockAudit has a global reach,
              with clients from all over the
              world, and helped 50+ companies
              towards better security."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/verify2.webp"
              title=" Commitment to Quality"
              description=" We are committed to providing our
              clients with the highest quality
              blockchain security consultation
              services. We are up-to-date on the
              latest blockchain security threats
              and mitigation techniques."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/user2.webp"
              title="Customer-centric Approach"
              description=" We take a customer-centric approach
              to blockchain security consultation.
              They work closely with their clients to
              understand their specific needs and
              develop tailored solutions to meet
              those needs.."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/chatt.webp"
              title="Detailed Plan"
              description="We provide detailed plans for
              security management that clearly
              explain the approach to mitigate
              vulnerabilities and reduce risks."
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
