import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Autoplay,
} from "swiper/modules";

const Feature = ({ icon, title, description }) => (
  <div className="flex justify-between flex-col sm:flex-row gap-4 items-center h-full">
    <img src={icon} alt={title} />
    <div>
      <h3 className="font-space text-base  text-center  font-bold mb-1">
        {title}
      </h3>
      <p
        className="font-kanit text-sm  font-extralight text-center "
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
);

export default function WhyBlock() {
  return (
    <>
      <Swiper
        navigation={true}
        loop={true}
        modules={[Navigation, EffectCoverflow, Pagination, Autoplay]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/watch.webp"
              title="Fast turnaround time"
              description=" BlockAudit typically completes audits
            within a few weeks."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/blub.webp"
              title="Customizable audits"
              description="   BlockAudit can customize its  audits to meet the specific needs of its clients."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/watch.webp"
              title="Competitive pricing"
              description=" BlockAudit offers competitive  pricing for its smart
         contract audits."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/glob.svg"
              title=" Global reach"
              description="    BlockAudit serves clients all over  the world."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/watch.webp"
              title="Competitive pricing"
              description=" BlockAudit offers competitive  pricing for its smart
         contract audits."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/ear.svg"
              title="Ongoing support"
              description="  we provides our clients 
            with  support after the audit is complete to
            help  them fix  vulnerabilities ."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/docs.svg"
              title="   Comprehensive reporting"
              description="  BlockAudit provides its
        clients with comprehensive audit  reports."
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
