import Hero_main from "components/Hero_main";

import React from "react";

function Hero() {
  return (
    <>
      <Hero_main
        Title="Token Rescue Service"
        Para="BlockAudit's Token Recovery Hotline is here to help. We specialize in rescuing funds from compromised crypto wallets due to phishing or private key leaks, offering expertise and support in the challenging world of web3 security."
        ButtonTitle="Fill the form"
        Href="https://noteforms.com/forms/blockaudit-token-recovery-form-8orbyu"
      />
    </>
  );
}

export default Hero;
