import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./kyc.json";
import { Col, Row } from "antd";
function SmartContractAudit() {
  return (
    <>
      <section className=" ">
        {/* <div className="flex items-center justify-between flex-col sm:flex-row gap-8"> */}
        {/* <div> */}

        <Row justify="center">
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center flex-col">
              <div>
                <h2 className=" font-space text-3xl sm:text-2xl font-bold   sm:text-start mt-5 sm:mt-2 mbl-center ">
                  How can we help?
                </h2>
                <p
                  className="text-[1.3rem] mt-5 w-full font-space mbl-center mbl-text "
                  style={{ fontWeight: "300" }}
                >
                  Compromised wallet keys turn into a race against time, and
                  BlockAudit is your ally in this battle. Our team specializes
                  in assisting with wallets that still hold funds or have
                  pending time-sensitive activities like unstaking. By employing
                  advanced techniques, we're not just reacting – we're staying a
                  step ahead of hackers to secure and recover your assets.
                  Complete the form provided, and our dedicated support team
                  will swiftly get in touch, ready to guide you through each
                  step of the recovery process.
                </p>
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center">
              <img
                src="images/blurred.webp"
                alt="BlockAudit Blurred 2"
                className=" mr-auto absolute top-0 right-0 sm:-right-[30%]"
              />
              <Lottie
                animationData={groovyWalkAnimation}
                loop={true}
                className=" flex justify-center ml-0 sm:ml-[30%] "
              />
            </div>
          </Col>
        </Row>

        {/* </div>
        
        </div> */}
      </section>
    </>
  );
}

export default SmartContractAudit;
