import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Autoplay,
} from "swiper/modules";

const Feature = ({ icon, title, description }) => (
  <div className="flex justify-between flex-col sm:flex-row gap-4 items-center h-full">
    <img src={icon} alt={title} />
    <div>
      <h3 className="font-space text-base  text-center  font-bold mb-1">
        {title}
      </h3>
      <p
        className="font-kanit text-sm  font-extralight text-center "
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
);

export default function WhyBlock() {
  return (
    <>
      <Swiper
        navigation={true}
        loop={true}
        modules={[Navigation, EffectCoverflow, Pagination, Autoplay]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/speed.webp"
              title="Rapid Response"
              description="BlockAudit swiftly responds to every case, ensuring timely intervention in critical situations."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/process.webp"
              title="Streamlined Procedures"
              description=" Our refined process guarantees 
              thorough and secure recovery operations. "
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/expert.webp"
              title="Unmatched Expertise"
              description=" We have a team of experienced
              KYC professionals who know how
              to verify identities in a secure
              and compliant way."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/repo.webp"
              title=" Trusted Leadership"
              description="  Renowned for reliability, BlockAudit is a trusted name in blockchain security solutions."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/doller.webp"
              title="Optimal Efficiency"
              description="We pride ourselves on efficient strategies that maximize recovery success.
              "
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/support.webp"
              title="Exceptional Support"
              description="Our dedicated customer support provides personalized assistance, ensuring clarity and comfort for clients."
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
