// import Blog from "components/Blog";
import React from "react";
import Partners from "sections/AuditPage/Partners";
// import RequestForm from "sections/AuditPage/RequestForm";
// import Hero from "sections/blogpage/Hero";
import Ourwork from "sections/blogpage/Ourwork";
import BlogSubs from "./blogSubs";
import AllBlog from "./allBlog";
import Sayabout from "sections/blogpage/Sayabout";
import HeroSection from "./heroSection";
// import "./Ad.css";

function BlogPage() {
  return (
    <div className="font-inter space-y-16 lg:space-y-24">
      <div className=" pt-4 sm:pt-12">
        <HeroSection />
      </div>
      {/* <div className="container-wrapper hidden lg:block">
        <hr className="text-white opacity-60" />
      </div> */}
      <div className=" py-2 sm:py-8">
        <Ourwork />
      </div>
      <div className=" py-2 sm:py-8">
        <AllBlog />
      </div>

      {/* <div className=" py-2 sm:py-8">
        <RequestForm />
      </div> */}

      <div className=" my-7 px-4 sm:px-2">
        <h1 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          Get a quote in less than 5 hours
        </h1>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      <div className=" py-2 sm:py-8">
        <Partners />
      </div>
      <div className=" py-2 sm:py-8">
        <Sayabout />
      </div>
      {/* <div className=" py-2 sm:py-8"><Blog /></div> */}
      <div className=" py-2 sm:py-8">
        <BlogSubs />
      </div>
    </div>
  );
}

export default BlogPage;
