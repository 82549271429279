import React from "react";

function DocumentNeeded() {
  return (
    <section className="container-wrapper block">
      <div className="bg-proccess px-7 sm:px-32 pb-20">
        <div className=" my-2 sm:my-7">
          <h2 className=" text-3xl sm:text-6xl font-space text-center font-bold pt-4 sm:pt-8">
            About the service
          </h2>
        </div>
        <div>
          <p className=" font-extralight text-base sm:text-2xl font-kanit py-2 sm:py-8">
            We offer comprehensive security consultancy services tailored to
            various organizations and individuals in the UAE and worldwide. Our
            expertise ranges from personalized guidance to strategic
            consultancy, encompassing risk assessments, audits, security
            planning, and technology insights. We help organizations address
            daily risks and navigate unfamiliar and complex environments,
            providing support for crisis management and decision-making. Our
            goal is to assist in identifying and mitigating risks, securing
            workplaces and infrastructure, and enhancing safety. We provide a
            unique and accessible capability, ensuring confidence in
            safeguarding what matters most.
          </p>
        </div>
      </div>
    </section>
  );
}

export default DocumentNeeded;
