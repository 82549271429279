import React from "react";
import Lottie from "lottie-react";
import groovyWalkAnimation from "./Cons.json";
import { Col, Row } from "antd";
function SmartContractAudit() {
  return (
    <>
      <section className=" ">
        <Row justify="center">
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center flex-col">
              <div>
                <h2 className=" font-space text-3xl sm:text-4xl font-bold mt-5 sm:mt-2 mbl-center">
                  What is Blockchain Security <br />
                  Consultation?
                </h2>
                <p
                  className="text-[1.3rem] mt-5 w-full font-space mbl-center mbl-text"
                  style={{ fontWeight: "300" }}
                >
                  Experienced experts offer a specialized service aimed at
                  assisting organizations in safeguarding their blockchain
                  ecosystems against cyber threats. This service involves
                  evaluating the security of an organization's blockchain
                  infrastructure, pinpointing potential vulnerabilities, and
                  formulating strategies to reduce these risks. These
                  professionals possess an in-depth knowledge of blockchain
                  technology and its distinct security complexities.
                </p>
              </div>
            </div>
          </Col>
          <Col xxl={6} xl={9} lg={9} md={10}>
            <div className="flex justify-center items-center">
              <Lottie
                animationData={groovyWalkAnimation}
                loop={true}
                className=" flex justify-center ml-0 sm:ml-[35%] "
              />
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default SmartContractAudit;
