import React from "react";

function AuditProccess() {
  return (
    <div className="container-wrapper">
      <div className="bg-proccess px-7 sm:px-32 pb-20 max-w-5xl mx-auto">
        <div className=" my-7">
          <h2 className=" text-3xl sm:text-6xl font-space text-center font-bold pt-4 sm:pt-8">
            Consultation Process
          </h2>
        </div>
        <div>
          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 pb-12 pt-10">
            <img src="images/pc.webp" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Initial consultation
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                The BlockAudit Consultant will meet with the project owner to
                understand their business needs, blockchain architecture, and
                security concerns.
              </p>
            </div>
          </div>
          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className="text-center sm:text-right font-space text-2xl font-bold pb-3 sm:pb-5">
                Assessment
              </p>
              <p className=" text-left sm:text-left  font-extralight text-base sm:text-xl font-kanit">
                We will begin by assessing the project owner’s blockchain
                environment, <br /> including its architecture, code, and
                security posture. This will help us <br /> to identify any
                potential vulnerabilities and risks.
              </p>
            </div>
            <img src="images/note.webp" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/date.webp" alt="" />
            <div>
              <p className=" font-space text-center sm:text-left text-2xl font-bold pb-3 sm:pb-5 ">
                Threat modeling
              </p>
              <p className=" font-extralight text-base sm:text-xl font-kanit">
                Once we understand the client's blockchain environment well, we
                will develop a threat model to identify the most likely threats
                and attack vectors.
              </p>
            </div>
          </div>

          <div className=" flex flex-col-reverse sm:flex-row items-center  justify-end max-w-5xl gap-14 sm:gap-20 py-12">
            <div>
              <p className=" sm:text-right text-center font-space text-2xl font-bold pb-3 sm:pb-5">
                Risk Assessment
              </p>
              <p className=" text-left  font-extralight text-base sm:text-xl font-kanit">
                Once we have identified any vulnerabilities, we will assess the
                risk each vulnerability <br /> poses to the client's blockchain
                environment. This will help us <br /> to prioritize the
                vulnerabilities and recommend remediation steps.
              </p>
            </div>
            <img src="images/chart.webp" alt="" />
          </div>

          <div className=" flex flex-col sm:flex-row items-center justify-between max-w-4xl gap-14 sm:gap-20 py-12">
            <img src="images/rader.webp" alt="" />
            <div>
              <p className=" font-space text-2xl font-bold pb-3 sm:pb-5 text-center sm:text-left ">
                Ongoing Monitoring
              </p>
              <p className="font-extralight text-base sm:text-xl font-kanit">
                We may also recommend ongoing monitoring of the client's
                blockchain environment to identify any new vulnerabilities or
                threats.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuditProccess;
