import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css/pagination";

import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import {
  Navigation,
  EffectCoverflow,
  Pagination,
  Autoplay,
} from "swiper/modules";

const Feature = ({ icon, title, description }) => (
  <div className="flex justify-between flex-col sm:flex-row gap-4 items-center h-full">
    <img src={icon} alt={title} />
    <div>
      <h3 className="font-space text-base  text-center  font-bold mb-1">
        {title}
      </h3>
      <p
        className="font-kanit text-sm  font-extralight text-center "
        dangerouslySetInnerHTML={{ __html: description }}
      />
    </div>
  </div>
);

export default function WhyBlock() {
  return (
    <>
      <Swiper
        navigation={true}
        loop={true}
        modules={[Navigation, EffectCoverflow, Pagination, Autoplay]}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={true}
        className="mySwiper"
      >
        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/start.svg"
              title="Experience"
              description=" We have a team of experienced security
              professionals who have conducted hundreds
              of smart contract audits and penetration tests"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/bulb_1.webp"
              title="Expertise"
              description="  Our team has a deep
              understanding of blockchain
              technology and the latest
              vulnerabilities"
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/time_1.webp"
              title="Track record"
              description=" We have a proven track record
              of identifying and mitigating
              security risks in blockchain
              projects."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/repo.webp"
              title=" Reputation"
              description="   BlockAudit is a well-respected
company in the blockchain
security industry."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/youtube.webp"
              title="Comprehensive services"
              description=" We offer a comprehensive suite
              of pen-testing services,
              including manual and
              automated testing."
            />
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="team-box">
            <Feature
              icon="images/doc_1.webp"
              title="Detailed reports"
              description=" We provide detailed reports that
              clearly explain the vulnerabilities
              that were found
              and how to fix them."
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </>
  );
}
