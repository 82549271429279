// import Blog from "components/Blog";
import Subscribe from "components/Subscribe/Subscribe";
import React from "react";
// import DocumentNeeded from "sections/KYC/DocumentNeeded";
// import AuditProccess from "sections/KYC/AuditProccess";
// import DocumentNeeded from "sections/KYC/DocumentNeeded";
import Hero from "sections/KYC/Hero";
import HighlyAccurate from "sections/KYC/HighlyAccurate";
import Ourwork from "sections/KYC/Ourwork";
// import Partners from "sections/KYC/Partners";
import Professional from "sections/KYC/Professional";
// import RequestForm from "sections/KYC/RequestForm";
import Sayabout from "sections/KYC/Sayabout";
import SmartContractAudit from "sections/KYC/SmartContractAudit";
// import Terms from "sections/KYC/Terms";
// import WhyAudit from "sections/KYC/WhyAudit";

function KycPage() {
  return (
    <div className="font-inter space-y-16 lg:space-y-24">
      <div className=" pt-4 sm:pt-12 margin-top">
        <Hero />
      </div>
      {/* <div className="container-wrapper hidden lg:block">
      <hr className="text-white opacity-60" />
    </div> */}
      <div className=" py-2 sm:py-8 margin-top1">
        <Ourwork />
      </div>
      {/* 
      <div className=" py-2 sm:py-8">
        <RequestForm />
      </div> */}

      <div className=" py-2">
        <SmartContractAudit />
      </div>
      {/* <div className=" py-2 sm:py-8">
        <WhyAudit />
      </div> */}
      <div className=" py-2 sm:py-8">
        <Professional />
      </div>

      <div className=" py-2 sm:py-8">
        <HighlyAccurate />
      </div>
      {/* <div className=" py-2 sm:py-8">
        <AuditProccess />
      </div> */}
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          Result-Based Payment – Your Recovery Triggers Our Fee
        </h2>

        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request for recovery now.
          </a>
        </div>
      </div>
      {/* <div className=" py-2 sm:py-8">
        <Partners />
      </div> */}

      <div className=" py-2 sm:py-8">
        <Sayabout />
      </div>

      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          We are now online to Assist you better,
        </h2>

        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      {/* <div className=" py-2 sm:py-8">
        <Terms />
      </div> */}

      {/* <div className=" py-2 sm:py-8">
        <Blog />
      </div> */}

      <div className=" py-2 sm:py-8">
        <Subscribe />
      </div>
    </div>
  );
}

export default KycPage;
