// import Paragraph from "components/Paragraph";
// import OutlinedBanner from "layouts/OutlinedBanner";
import Blog from "components/Blog";
import TeamHome from "components/Team/TeamHome";
import React from "react";
import Partners from "sections/AuditPage/Partners";
import RequestForm from "sections/AuditPage/RequestForm";
import Terms from "sections/AuditPage/Terms";
// import Sayabout from "sections/KYC/Sayabout";
import AuditProccess from "sections/PenTestPage/AuditProccess";
// import Benefits from "sections/PenTestPage/Benefits";
// import Ecosystem from "sections/PenTestPage/Ecosystem";
import Hero from "sections/PenTestPage/Hero";
import HighlyAccurate from "sections/PenTestPage/HighlyAccurate";
import HowWeDiffer from "sections/PenTestPage/HowWeDiffer";
import Ourwork from "sections/PenTestPage/Ourwork";

import WhatWeOffer from "sections/PenTestPage/WhatWeOffer";

function DuePage() {
  return (
    <div className="space-y-20 lg:space-y-24 2xl:space-y-32">
      <div className="pt-4 sm:pt-12 margin-top">
        <Hero />
      </div>
      <div className=" py-2 sm:py-8 margin-top1">
        <Ourwork />
      </div>
      {/* <section className="container-wrapper ">
        <OutlinedBanner>
          <Paragraph>
            Penetration test in crypto is the assessments and simulated test in
            blockchain network by attacking by top security specialists. The
            pentester are highly qualified and have a deep knowledge about
            blockchain operations.
          </Paragraph>
        </OutlinedBanner>
      </section> */}

      {/* <div className=" py-2 sm:py-8">
        <RequestForm/>
      </div> */}
      <div className=" py-2 sm:py-8">
        <WhatWeOffer />
      </div>
      <div className=" py-2 sm:py-8">
        <HowWeDiffer />
      </div>
      <div className=" py-2 sm:py-8">
        {/* <Ecosystem /> */}
        <HighlyAccurate />
      </div>
      <div className=" py-2 sm:py-8">
        <AuditProccess />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          Get a quote in less than 5 hours
        </h2>
        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      <div className=" py-2 sm:py-8">
        <Partners />
      </div>
      <div className=" py-2 sm:py-8">
        <TeamHome />
      </div>
      <div className=" my-7 px-4 sm:px-2">
        <h2 className=" text-center text-3xl sm:text-6xl font-space font-bold">
          We are now online to Assist you better,
        </h2>

        <div className="flex justify-center pt-8">
          <a
            href="/"
            className="btn-red-one text-lg font-bold font-space py-4 px-9 mx-auto"
          >
            Request an Audit now.
          </a>
        </div>
      </div>
      {/* <div className=" py-2 sm:py-8">
        <Terms/>
        </div> */}

      {/* <div className=" py-2 sm:py-8">
        <Blog />
      </div> */}
    </div>
  );
}

export default DuePage;
